import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { AllPaymentItem } from '../type/payment.type';
import {
  CashFlowType,
  CreateExpenseType,
  HouseStatusType,
  NationalityStatisticType,
  StayingGuestType,
  ShiftHandoverResponse,
  ReservationForcecastType,
  FocListType,
  FocsDetailsType
} from '../type/report.type';
import { trimTruthyValues } from 'utils';

export const PAGE_SIZE_SHIFT_HANDOVER = 15;
export const getListShiftHandover = async ({
  date_from,
  date_to,
  limit,
  page
}: {
  date_from: string;
  date_to: string;
  limit?: number;
  page?: number;
}): Promise<ShiftHandoverResponse> => {
  const params = {
    date_from,
    date_to,
    limit,
    page
  };
  return axiosInstance
    .get(paths.getListShiftHandover(), {
      params
    })
    .then(item => item.data);
};

export const PAGE_SIZE_STAYING_GUESTS = 10;
export const getStayingGuests = ({
  start_date,
  end_date,
  status,
  country,
  limit,
  page
}: {
  start_date: string;
  end_date: string;
  status: string;
  country: string;
  limit?: number;
  page?: number;
}): Promise<StayingGuestType[]> => {
  const params = {
    start_date,
    end_date,
    status,
    limit,
    page
  } as any;
  if (country) {
    params.country = country;
  }

  return axiosInstance
    .get(paths.getStayingGuests(), {
      params
    })
    .then(item => item.data);
};

export const PAGE_SIZE_NATIONALITY_STATISTIC = 10;
export const getAllNationality = ({
  start_date,
  end_date,
  status
}: {
  start_date: string;
  end_date: string;
  status: string;
}): Promise<NationalityStatisticType[]> => {
  const params = {
    start_date,
    end_date,
    status
  } as any;
  return axiosInstance
    .get(paths.getAllNationality(), {
      params
    })
    .then(item => item.data);
};

export const PAGE_SIZE_CASH_FLOW = 10;
export const getCashFlow = ({
  start_date,
  end_date,
  booking_id,
  limit,
  page
}: {
  start_date: string;
  end_date: string;
  booking_id?: number;
  limit?: number;
  page?: number;
}): Promise<CashFlowType[]> => {
  return axiosInstance
    .get(paths.getCashFlow(), {
      params: { start_date, end_date, booking_id, limit, page }
    })
    .then(item => item.data);
};

export const createExpense = (params: CreateExpenseType): Promise<CashFlowType[]> => {
  return axiosInstance.post(paths.createExpense(), params).then(item => item.data.data);
};

export const getRoomAvailability = async (start_date: string, period: number): Promise<any> => {
  return axiosInstance
    .get(paths.getRoomAvailability(), {
      params: {
        start_date,
        period
      }
    })
    .then(item => item.data.data);
};

export const getRoomSummary = async (date: string): Promise<any> => {
  return axiosInstance
    .get(paths.getRoomSummary(), {
      params: {
        date
      }
    })
    .then(item => item.data.data);
};

export const PAGE_SIZE_TRANSACTION = 10;
export const getTransactions = async (filterOptions: any): Promise<AllPaymentItem[]> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.getTransactions(), {
      params: truthyFilterOptions
    })
    .then(res => res.data);
};

export const getReservationForecast = async (
  filterOptions: ReservationForcecastType
): Promise<any> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.getReservationForecast(), {
      params: truthyFilterOptions
    })
    .then(item => item.data.data);
};

export const getHouseStatus = async (date: string): Promise<HouseStatusType> => {
  return axiosInstance
    .get(paths.getHouseStatus(), {
      params: {
        date
      }
    })
    .then(item => item.data.data);
};

export const getFocList = async (filterOptions: FocListType): Promise<any> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.focList(), {
      params: truthyFilterOptions
    })
    .then(item => item.data.data);
};

export const getFocsDetails = async (filterOptions: FocsDetailsType): Promise<any> => {
  const truthyFilterOptions = trimTruthyValues(filterOptions);
  return axiosInstance
    .get(paths.focDetails(), {
      params: truthyFilterOptions
    })
    .then(item => item.data.data);
};
