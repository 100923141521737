import { FocListType, FocsDetailsType } from 'services/api/type/report.type';
import { useQuery } from '@tanstack/react-query';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { useMemo } from 'react';

export const useGetFocList = (filterOptions: FocListType) => {
  const { data, status, isLoading, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_FOC_LIST, filterOptions],
    queryFn: () => API.report.getFocList(filterOptions),
    enabled: !!filterOptions
  });
  const convertedData = useMemo(() => {
    return Object.keys(data || {}).map(key => ({
      ...data[key],
      roomType: key
    }));
  }, [data]);

  return {
    data: convertedData || [],
    status,
    isLoading,
    isFetching
  };
};

export const useGetFocsDetails = (filterOptions: FocsDetailsType, isEnable: boolean = true) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_FOC_DETAILS, filterOptions],
    queryFn: () => API.report.getFocsDetails(filterOptions),
    enabled: isEnable
  });

  return {
    data: data,
    isLoading
  };
};
