import React, { useState, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Table, Form, Input, Button, TableProps } from 'antd';
import { toUtcTime } from 'utils';
import 'styles/foc-list.scss';
import { FormProps } from 'rc-field-form';
import _ from 'lodash';
import { useGetFocList } from 'hooks/useGetFocs';
import useModal from 'stores/useModal';
import ConfirmFocReport from 'components/modal/foc-report-components/confirm-foc-report';

const INITIAL_RANGE_DATE = 14;
const initStartDate = dayjs().startOf('day');

function FocList() {
  const [form] = Form.useForm();

  const [selectedDateRange, setSelectedDateRange] = useState<any>([
    initStartDate,
    initStartDate.add(INITIAL_RANGE_DATE, 'day')
  ]);
  const { isOpenFocDetails, setIsOpenFocDetails } = useModal();
  const [dateToQuery, setDateToQuery] = useState<Dayjs | undefined>();
  const [roomTypeToQuery, setRoomTypeToQuery] = useState<string>('');

  const onCancelFocDetails = () => {
    setIsOpenFocDetails(false);
  };

  const onHandleClose = () => {
    setIsOpenFocDetails(false);
  };
  const [filterOptions, setFilterOptions] = useState<any>({
    check_in: toUtcTime(initStartDate.add(1, 'day'), 'YYYY-MM-DD'),
    period: INITIAL_RANGE_DATE
  });
  const { data: focList, isFetching } = useGetFocList(filterOptions);

  const onHandleConfirmFocList = (date?: Dayjs, roomType?: string) => {
    setIsOpenFocDetails(true);
    setDateToQuery(date);
    setRoomTypeToQuery(roomType ?? '');
  };
  const columnFocList: TableProps<any>['columns'] = useMemo(() => {
    const [startDate, endDate] = selectedDateRange;

    if (!startDate.isValid() || !endDate.isValid()) {
      return [];
    }

    function isClickableCell(roomType: string, cellValue: any, totalRooms: number): boolean {
      const nonClickableTypes = new Set(['TOTAL']);
      return !nonClickableTypes.has(roomType) && cellValue !== totalRooms;
    }

    let currentStartDate = dayjs(startDate);
    const columns: TableProps<any>['columns'] = [
      {
        title: 'Loại phòng',
        dataIndex: 'roomType',
        key: 'roomType',
        align: 'center',
        fixed: 'left',
        width: 355
      },
      {
        title: 'Số lượng phòng',
        dataIndex: 'total_rooms',
        key: 'total_rooms',
        fixed: 'left',
        align: 'center',
        width: 135
      }
    ];

    while (!currentStartDate.isSame(endDate, 'day')) {
      const addedColumn = currentStartDate.format('YYYY-MM-DD');
      columns.push({
        align: 'center',
        title: currentStartDate.format('DD/MM'),
        dataIndex: addedColumn,
        key: addedColumn,
        width: 90,
        render: (text: any, record: any) => {
          const isClickable = isClickableCell(record.roomType, text, record.total_rooms);
          return (
            <p className="m-0 text-right" style={{ display: 'flex', justifyContent: 'center' }}>
              {isClickable ? (
                <a onClick={() => onHandleConfirmFocList(dayjs(addedColumn), record['roomType'])}>
                  {text}
                </a>
              ) : (
                text
              )}
            </p>
          );
        },
        onCell: (record: any) => {
          const isClickable = isClickableCell(
            record.roomType,
            record[addedColumn],
            record.total_rooms
          );
          return isClickable
            ? {
                onClick: () => onHandleConfirmFocList(dayjs(addedColumn), record['roomType']),
                style: { cursor: 'pointer' }
              }
            : {};
        }
      });
      currentStartDate = currentStartDate.add(1, 'day');
    }
    return columns;
    // eslint-disable-next-line
  }, [selectedDateRange]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, period, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];

      if (startDate && endDate) {
        const dayCount = endDate.diff(startDate, 'day');
        _.set(restFilterOptions, 'period', dayCount);
        _.set(restFilterOptions, 'check_in', toUtcTime(startDate.add(1, 'day'), 'YYYY-MM-DD'));

        setSelectedDateRange([startDate, endDate]);
      }
    }

    _.set(restFilterOptions, 'period', period || INITIAL_RANGE_DATE);

    setFilterOptions(restFilterOptions);
  };

  const handleRangeDateChange = (dates: any) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const dayCount = end.diff(start, 'day');
      form.setFieldsValue({ period: dayCount });
    }
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period = parseInt(e.target.value);
    if (!isNaN(period)) {
      const startDate = dayjs(form.getFieldValue('rangeDate')[0]);
      const endDate = startDate.add(period, 'day');
      form.setFieldsValue({ rangeDate: [startDate, endDate] });
    }
  };

  return (
    <div className="pms-foc-list">
      <div className="pms-foc-list__timetable">
        <Form
          form={form}
          initialValues={{
            rangeDate: [initStartDate, initStartDate.add(INITIAL_RANGE_DATE, 'day')],
            period: INITIAL_RANGE_DATE
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-foc-list__filter">
            <div className="pms-foc-list__filter__left">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  className="w-full"
                  placeholder={['Từ ngày', 'Đến ngày']}
                  format="DD-MM-YYYY"
                  onChange={handleRangeDateChange}
                />
              </Form.Item>
              <Form.Item name="period">
                <Input placeholder="Số ngày" onChange={handlePeriodChange} />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                  Tìm kiếm
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
        <div className="pms-foc-list__table">
          <Table
            rowKey="roomType"
            loading={isFetching}
            columns={columnFocList}
            dataSource={focList}
            pagination={false}
            scroll={{ x: 1000, y: 'calc(100vh - 320px)' }}
            bordered
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
          />
        </div>
      </div>
      <ConfirmFocReport
        dateToQuery={dateToQuery}
        roomTypeToQuery={roomTypeToQuery}
        isOpen={isOpenFocDetails}
        onCancel={() => onCancelFocDetails()}
        onHandleClose={() => onHandleClose()}
      />
    </div>
  );
}

export default FocList;
