import { getEndOfTommorow, getStartOfRightDay } from 'utils';
import { columnFocDetailsTab } from 'constants/table';
import CustomTable from '../room-available-components/CustomTable';
import { useGetFocsDetails } from 'hooks/useGetFocs';

interface FocsDetailsProps {
  isEnable: boolean;
  dateToQuery: any;
  roomTypeToQuery: string;
}

export default function FocsDetailsTab({
  isEnable,
  dateToQuery,
  roomTypeToQuery
}: FocsDetailsProps) {
  const { data: focDetails, isLoading } = useGetFocsDetails(
    {
      check_in: getStartOfRightDay(dateToQuery).format('YYYY-MM-DD HH:mm:ss'),
      check_out: getEndOfTommorow(dateToQuery).format('YYYY-MM-DD HH:mm:ss'),
      short_code: roomTypeToQuery
    },
    isEnable
  );

  return (
    <CustomTable
      rowKey="id"
      loading={isLoading}
      columns={columnFocDetailsTab}
      dataSource={focDetails || []}
    />
  );
}
