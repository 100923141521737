import _ from 'lodash';
import { Button, Checkbox, Dropdown, InputNumber, MenuProps, Select, TableProps } from 'antd';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

import {
  PrinterOutlined,
  // EllipsisOutlined,
  EditFilled,
  PlusOutlined,
  MinusOutlined,
  UserOutlined
} from '@ant-design/icons';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { ReactComponent as IconBed } from 'assets/images/bed.svg';
import { ReactComponent as IconExclamation } from 'assets/images/exclamation-circle.svg';
import { ReactComponent as IconPlusCircle } from 'assets/images/plus-circle.svg';
import { ReactComponent as IconCalendar } from 'assets/images/calendar.svg';
import { ReactComponent as IconSwap } from 'assets/images/swap.svg';
import { ReactComponent as IconAssign } from 'assets/images/assign.svg';
import { ReactComponent as IconCancelAssign } from 'assets/images/cancel-assign.svg';
// import { ReactComponent as IconStop } from "assets/images/stop.svg";
// import { ReactComponent as IconCopy } from "assets/images/copy.svg";
import { ReactComponent as IconBaby } from 'assets/images/baby.svg';
import { ReactComponent as IconClean } from 'assets/images/clean-v2.svg';
import { ReactComponent as IconDirty } from 'assets/images/dirty.svg';
import { ReactComponent as IconFix } from 'assets/images/fix.svg';
import { ReactComponent as IconMore } from 'assets/images/more.svg';

import { RoomAssignType, ServiceType, RoomType, RoomLockType } from 'services/api/type/room.type';
import { BookingLine, BookingStatus } from 'services/api/type/booking.type';
import { formatCurrency, formatNumber, toLocalTime, formatMoneyValue } from 'utils';
import { BOOKING_STATUS, MAP_VIP_LEVEL, PAYMENT_METHOD_NAMES, PAYMENT_STATUS } from './form';
import { CASHFLOW_TYPE, MAP_GENDER, MAP_NIGHT_AUDIT_STATUS } from './common';
import { CountryType } from 'services/api/type/common.type';
import { PAGE_SIZE_CASH_FLOW, PAGE_SIZE_STAYING_GUESTS } from 'services/api/module/report.api';
import { AllPaymentItem } from 'services/api/type/payment.type';
import { RoomNoRegex } from './regex';
import { MAP_PAYMENT_TYPE } from './text';
import { NationalityStatisticType } from 'services/api/type/report.type';
import { ShiftHandover } from 'services/api/type/shift-handover.type';
import { WarehouseProductsType } from 'services/api/type/warehouse-management.type';
import { ResponseGroupBookings, SubGroup } from 'services/api/type/group.type';
import { formatInputNumber } from 'utils/currency';
import { TravelAgencyResponse } from 'services/api/type/travel-agency.type';

export const MappingColumnBookingStatus: any = {
  [BOOKING_STATUS.CONFIRM]: 'Đã đặt',
  [BOOKING_STATUS.ALLOT]: 'Đang ở',
  [BOOKING_STATUS.CANCEL]: 'Hủy',
  [BOOKING_STATUS.CHECKOUT]: 'Đã đi'
};

export const columnAssignRoom: TableProps<RoomAssignType>['columns'] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '106px'
  },
  {
    title: 'Tên phòng',
    dataIndex: 'roomName',
    key: 'roomName'
  },
  {
    title: 'Đêm',
    dataIndex: 'night',
    key: 'night',
    width: '170px'
  },
  {
    title: 'Người lớn',
    dataIndex: 'adult',
    key: 'adult',
    width: '170px'
  },
  {
    title: 'Trẻ em',
    dataIndex: 'numberChildren',
    key: 'numberChildren',
    width: '170px'
  },
  {
    title: 'Giá',
    key: 'price',
    dataIndex: 'price',
    width: '170px',
    render: (_, { price }) => `${formatCurrency(price)} đ`
  }
];

export const fakeDataAssignRoom: RoomAssignType[] = [
  {
    key: 1,
    id: '000123',
    roomName: '9DS81 Kin Inspired 1- 301',
    night: 1,
    adult: 2,
    numberChildren: 1,
    price: 18000000
  },
  {
    key: 2,
    id: '000124',
    roomName: '7HK99 Kin Inspired 1- 777',
    night: 1,
    adult: 3,
    numberChildren: 2,
    price: 20000000
  }
];

export const columnService: TableProps<ServiceType>['columns'] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '106px'
  },
  {
    title: 'Tên dịch vụ',
    dataIndex: 'serviceName',
    key: 'serviceName'
  },
  {
    title: 'Số lượng',
    dataIndex: 'quantity',
    key: 'quantity',
    width: '220px'
  },
  {
    title: 'Giá',
    key: 'price',
    dataIndex: 'price',
    width: '220px',
    render: (_, { price }) => `${formatCurrency(price)} đ`
  }
];

export const ACTION_BOOKING = {
  DETAIL: 'DETAIL',
  DETAIL_DIVIDER: 'DETAIL_DIVIDER',
  DETAIL_GROUP: 'DETAIL_GROUP',
  GET_ROOM: 'GET_ROOM',
  ADD_SERVICE: 'ADD_SERVICE',
  CHANGE_DATE: 'CHANGE_DATE',
  CHANGE_ROOM: 'CHANGE_ROOM',
  CHANGE_PRICE: 'CHANGE_PRICE',
  CANCEL_BOOKING: 'CANCEL_BOOKING',
  CANCEL_BOOKING_DIVIDER: 'CANCEL_BOOKING_DIVIDER',
  QUICK_GET_ROOM: 'QUICK_GET_ROOM',
  DIRTY_ROOM: 'DIRTY_ROOM',
  CANCEL_ASSIGN_ROOM: 'CANCEL_ASSIGN_ROOM',
  COPY: 'COPY',
  PRINT_GROUP: 'PRINT_GROUP',
  CANCEL_GROUP: 'CANCEL_GROUP',
  CLEAN_ROOM: 'CLEAN_ROOM',
  ASSIGN_ROOM: 'ASSIGN_ROOM',
  CHANGE_ROOM_TYPE: 'CHANGE_ROOM_TYPE',
  FIX_ROOM: 'FIX_ROOM',
  CHECK_OUT: 'CHECK_OUT',
  RESOLVE_DOCUMENT: 'RESOLVE_DOCUMENT'
};

export const ACTION_BOOKING_LIST: MenuProps['items'] = [
  {
    key: ACTION_BOOKING.DETAIL,
    label: (
      <div className="action-item">
        <IconExclamation />
        <span>Chi tiết</span>
      </div>
    )
  },
  {
    label: (
      <div className="action-item">
        <IconBed />
        <span>Nhận phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.GET_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconAssign />
        <span>Gán phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.ASSIGN_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconCancelAssign />
        <span>Bỏ gán phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.CANCEL_ASSIGN_ROOM
  },
  {
    type: 'divider',
    key: ACTION_BOOKING.DETAIL_DIVIDER
  },
  {
    label: (
      <div className="action-item">
        <IconPlusCircle />
        <span>Thêm dịch vụ</span>
      </div>
    ),
    key: ACTION_BOOKING.ADD_SERVICE
  },
  {
    label: (
      <div className="action-item">
        <IconCalendar />
        <span>Đổi ngày ở</span>
      </div>
    ),
    key: ACTION_BOOKING.CHANGE_DATE
  },
  {
    label: (
      <div className="action-item">
        <IconSwap />
        <span>Chuyển phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.CHANGE_ROOM
  },
  {
    type: 'divider',
    key: ACTION_BOOKING.CANCEL_BOOKING_DIVIDER
  },
  {
    label: (
      <div className="action-item">
        <IconClose />
        <span>Huỷ đặt phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.CANCEL_BOOKING
  }
];

export const ACTION_BOOKING__GROUP_LIST: MenuProps['items'] = [
  {
    key: ACTION_BOOKING.DETAIL_GROUP,
    label: (
      <div className="action-item">
        <IconExclamation />
        <span>Chi tiết đoàn</span>
      </div>
    )
  },
  {
    label: (
      <div className="action-item">
        <PrinterOutlined style={{ color: '#00000040' }} />
        <span>In bản xác nhận</span>
      </div>
    ),
    key: ACTION_BOOKING.PRINT_GROUP
  },
  {
    label: (
      <div className="action-item">
        <IconClose />
        <span>Hủy đoàn</span>
      </div>
    ),
    key: ACTION_BOOKING.CANCEL_GROUP
  }
];

export const getBookingListColumns = (
  onClick: MenuProps['onClick'],
  isUseActualTime: boolean = false
) => {
  const columnBookingList: TableProps<BookingLine>['columns'] = [
    {
      title: '#',
      dataIndex: 'booking_id',
      key: 'booking_id',
      width: '80px'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      render: text => MappingColumnBookingStatus[text] || ''
    },
    {
      title: 'Phòng',
      dataIndex: 'room_name',
      key: 'room_name',
      width: '160px',
      render: (value, record) => (
        <span className="room-id">
          {record.original_room_type_name !== record.room_type_name && (
            <span className="original-room-type" style={{ color: 'rgba(0, 0, 0, 0.2509803922)' }}>
              {record.original_room_type_name} &gt;
            </span>
          )}{' '}
          {record.room_name || record.room_type_name + ' N/A'}
        </span>
      )
    },
    {
      title: 'Tên khách',
      dataIndex: 'partner_name',
      key: 'partner_name',
      width: '160px'
    },
    {
      title: 'Nhóm',
      dataIndex: 'labels',
      key: 'labels',
      width: '160px',
      render: (value, record) => (
        <span className="labels">
          {record.labels.map(label => (
            <span
              key={label.name}
              style={{
                backgroundColor: label.color,
                color: '#fff',
                padding: '2px 5px',
                margin: '0 2px',
                borderRadius: '3px'
              }}
            >
              {label.name}
            </span>
          ))}
        </span>
      )
    },
    {
      title: 'Ngày đến',
      dataIndex: 'check_in',
      key: 'check_in',
      width: '180px',
      render: value => dayjs(value).format('DD/MM/YYYY - HH:mm')
    },
    {
      title: 'Ngày đi',
      dataIndex: 'check_out',
      key: 'check_out',
      render: value => dayjs(value).format('DD/MM/YYYY - HH:mm'),
      width: '180px'
    },
    {
      title: 'Loại giá',
      dataIndex: 'pricelist',
      key: 't',
      width: '120px',
      render: value => value?.name || 'Giá mặc định'
    },
    {
      title: 'NL/TE',
      dataIndex: 'adult',
      key: 'adult',
      width: '100px'
    },
    {
      title: 'Công ty',
      dataIndex: 'medium_name',
      key: 'medium_name',
      width: '130px'
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      width: '220px'
    }
  ];

  if (isUseActualTime) {
    columnBookingList.splice(6, 0, {
      title: 'Ngày đi thực tế',
      dataIndex: 'actual_check_out',
      key: 'actual_check_out',
      render: value => dayjs(value).format('DD/MM/YYYY - HH:mm'),
      width: '180px'
    });
  }

  columnBookingList.forEach(col => {
    if ((col as any).dataIndex === 'partner_name') {
      col.onCell = record => ({
        colSpan: !_.isEmpty(record.expandableRows) ? 10 : 1,
        className: !RoomNoRegex.test(record.room_name) ? 'not-assigned' : ''
      });
    } else {
      col.onCell = record => ({
        colSpan: !_.isEmpty(record.expandableRows) ? 0 : 1,
        className: !RoomNoRegex.test(record.room_name) ? 'not-assigned' : ''
      });
    }

    const existingRender = col.render;

    col.render = (text, record, index) => {
      // if (!_.isEmpty(record.expandableRows)) {
      //   return (
      //     <div className="ant-table-cell__inner ant-table-cell__expand">
      //       <span>{text}</span>
      //       <Dropdown
      //         trigger={["click"]}
      //         menu={{ items: ACTION_BOOKING__GROUP_LIST, onClick }}
      //         placement="bottom"
      //         overlayClassName="action-booking-list"
      //         destroyPopupOnHide
      //       >
      //         <Button
      //           icon={<EllipsisOutlined style={{ fontSize: "18px" }} />}
      //           type="text"
      //         />
      //       </Dropdown>
      //     </div>
      //   );
      // }

      const menu = ACTION_BOOKING_LIST.filter(item => {
        if (
          (item?.key === ACTION_BOOKING.CANCEL_BOOKING ||
            item?.key === ACTION_BOOKING.CANCEL_BOOKING_DIVIDER) &&
          record.status !== BookingStatus.CONFIRM
        ) {
          return false;
        }

        if (
          (item?.key === ACTION_BOOKING.GET_ROOM ||
            item?.key === ACTION_BOOKING.CANCEL_ASSIGN_ROOM) &&
          (record.status !== BookingStatus.CONFIRM ||
            (record.status === BookingStatus.CONFIRM && !record.room_id))
        ) {
          return false;
        }

        if (
          item?.key === ACTION_BOOKING.ASSIGN_ROOM &&
          (record.status !== BookingStatus.CONFIRM ||
            (record.status === BookingStatus.CONFIRM && record.room_id))
        ) {
          return false;
        }

        if (
          [
            ACTION_BOOKING.DETAIL_DIVIDER,
            ACTION_BOOKING.ADD_SERVICE,
            ACTION_BOOKING.CHANGE_DATE,
            ACTION_BOOKING.CHANGE_ROOM
          ].includes(String(item?.key)) &&
          [BookingStatus.CANCEL, BookingStatus.CHECK_OUT].includes(record.status)
        ) {
          return false;
        }
        return true;
      });

      return (
        <Dropdown
          trigger={['click']}
          menu={{ items: menu, onClick }}
          placement="bottom"
          overlayClassName={classNames('action-booking-list', {
            'action-booking-list--auto': !_.isEmpty(record.expandableRows)
          })}
          destroyPopupOnHide
        >
          {(col as any).dataIndex === 'adult' ? (
            <div>
              {text}/{record.child}
            </div>
          ) : (
            <div>{existingRender ? existingRender(text, record, index) : text}</div>
          )}
        </Dropdown>
      );
    };
  });

  return columnBookingList;
};

export const getBookingListColumnsExpand = (
  onClick: MenuProps['onClick'],
  isUseActualTime: boolean = false
) => {
  const columnBookingList: TableProps<BookingLine>['columns'] = [
    {
      title: '#',
      dataIndex: 'booking_id',
      key: 'booking_id',
      width: '80px'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      render: text => MappingColumnBookingStatus[text] || ''
    },
    {
      title: 'Phòng',
      dataIndex: 'room_name',
      key: 'room_name',
      width: '160px',
      render: (value, record) => (
        <span className="room-id">
          {record.original_room_type_name !== record.room_type_name && (
            <span className="original-room-type" style={{ color: 'rgba(0, 0, 0, 0.2509803922)' }}>
              {record.original_room_type_name} &gt;
            </span>
          )}{' '}
          {record.room_name || record.room_type_name + ' N/A'}
        </span>
      )
    },
    {
      title: 'Tên khách',
      dataIndex: 'partner_name',
      key: 'partner_name',
      width: '160px'
    },
    {
      title: 'Nhóm',
      dataIndex: 'labels',
      key: 'labels',
      width: '160px',
      render: (value, record) => (
        <span className="labels">
          {record.labels.map(label => (
            <span
              key={label.name}
              style={{
                backgroundColor: label.color,
                color: '#fff',
                padding: '2px 5px',
                margin: '0 2px',
                borderRadius: '3px'
              }}
            >
              {label.name}
            </span>
          ))}
        </span>
      )
    },
    {
      title: 'Ngày đến',
      dataIndex: 'check_in',
      key: 'check_in',
      render: value => dayjs(value).format('DD/MM/YYYY - HH:mm'),
      width: '180px'
    },
    {
      title: 'Ngày đi',
      dataIndex: 'check_out',
      key: 'check_out',
      render: value => dayjs(value).format('DD/MM/YYYY - HH:mm'),
      width: '180px'
    },
    {
      title: 'Loại giá',
      dataIndex: 'pricelist',
      key: 't',
      width: '120px',
      render: value => value?.name || 'Giá mặc định'
    },
    {
      title: 'NL/TE',
      dataIndex: 'adult',
      key: 'adult',
      width: '100px'
    },
    {
      title: 'Công ty',
      dataIndex: 'medium_name',
      key: 'medium_name',
      width: '130px'
    },
    {
      title: 'Ghi chú',
      dataIndex: 'note',
      key: 'note',
      width: '220px'
    }
  ];

  if (isUseActualTime) {
    columnBookingList.splice(6, 0, {
      title: 'Ngày đi thực tế',
      dataIndex: 'actual_check_out',
      key: 'actual_check_out',
      render: value => dayjs(value).format('DD/MM/YYYY - HH:mm'),
      width: '180px'
    });
  }

  columnBookingList.forEach(col => {
    const existingRender = col.render;

    col.onCell = record => ({
      className: !RoomNoRegex.test(record.room_name) ? 'not-assigned' : ''
    });

    col.render = (text, record, index) => {
      const menu = ACTION_BOOKING_LIST.filter(item => {
        if (
          (item?.key === ACTION_BOOKING.CANCEL_BOOKING ||
            item?.key === ACTION_BOOKING.CANCEL_BOOKING_DIVIDER) &&
          record.status !== BookingStatus.CONFIRM
        ) {
          return false;
        }

        if (
          (item?.key === ACTION_BOOKING.GET_ROOM ||
            item?.key === ACTION_BOOKING.CANCEL_ASSIGN_ROOM) &&
          (record.status !== BookingStatus.CONFIRM ||
            (record.status === BookingStatus.CONFIRM && !record.room_id))
        ) {
          return false;
        }

        if (
          item?.key === ACTION_BOOKING.ASSIGN_ROOM &&
          (record.status !== BookingStatus.CONFIRM ||
            (record.status === BookingStatus.CONFIRM && record.room_id))
        ) {
          return false;
        }

        if (
          [
            ACTION_BOOKING.DETAIL_DIVIDER,
            ACTION_BOOKING.ADD_SERVICE,
            ACTION_BOOKING.CHANGE_DATE,
            ACTION_BOOKING.CHANGE_ROOM
          ].includes(String(item?.key)) &&
          [BookingStatus.CANCEL, BookingStatus.CHECK_OUT].includes(record.status)
        ) {
          return false;
        }
        return true;
      });

      return (
        <Dropdown
          trigger={['click']}
          menu={{ items: menu, onClick }}
          placement="bottom"
          overlayClassName="action-booking-list"
          destroyPopupOnHide
        >
          {(col as any).dataIndex === 'adult' ? (
            <div>
              {text}/{record.child}
            </div>
          ) : (
            <div>{existingRender ? existingRender(text, record, index) : text}</div>
          )}
        </Dropdown>
      );
    };
  });

  return columnBookingList;
};

export const ACTION_ROOM_ITEM_EMPTY: MenuProps['items'] = [
  // {
  //   key: ACTION_BOOKING.QUICK_GET_ROOM,
  //   label: (
  //     <div className="action-item">
  //       <IconBed />
  //       <span>Nhận phòng nhanh</span>
  //     </div>
  //   ),
  // },
  {
    label: (
      <div className="action-item">
        <IconDirty />
        <span>Phòng bẩn</span>
      </div>
    ),
    key: ACTION_BOOKING.DIRTY_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconClean />
        <span>Làm sạch</span>
      </div>
    ),
    key: ACTION_BOOKING.CLEAN_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconFix />
        <span>Sửa phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.FIX_ROOM
  }
];

export const ACTION_ROOM_ITEM: MenuProps['items'] = [
  {
    key: ACTION_BOOKING.DETAIL,
    label: (
      <div className="action-item">
        <IconExclamation />
        <span>Chi tiết</span>
      </div>
    )
  },
  {
    label: (
      <div className="action-item">
        <IconDirty />
        <span>Phòng bẩn</span>
      </div>
    ),
    key: ACTION_BOOKING.DIRTY_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconClean />
        <span>Làm sạch</span>
      </div>
    ),
    key: ACTION_BOOKING.CLEAN_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconBed />
        <span>Nhận phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.GET_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconCancelAssign />
        <span>Bỏ gán phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.CANCEL_ASSIGN_ROOM
  },
  {
    type: 'divider',
    dashed: true
  },
  {
    label: (
      <div className="action-item">
        <IconPlusCircle />
        <span>Thêm dịch vụ</span>
      </div>
    ),
    key: ACTION_BOOKING.ADD_SERVICE
  },
  {
    label: (
      <div className="action-item">
        <IconCalendar />
        <span>Đổi ngày ở</span>
      </div>
    ),
    key: ACTION_BOOKING.CHANGE_DATE
  },
  {
    label: (
      <div className="action-item">
        <IconSwap />
        <span>Chuyển phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.CHANGE_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconFix />
        <span>Sửa phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.FIX_ROOM
  },
  {
    type: 'divider',
    key: `${ACTION_BOOKING.CANCEL_BOOKING}-divider`
  },
  {
    label: (
      <div className="action-item">
        <IconClose />
        <span>Huỷ đặt phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.CANCEL_BOOKING
  }
  // {
  //   label: (
  //     <div className="action-item">
  //       <IconCopy />
  //       <span>Sao chép</span>
  //     </div>
  //   ),
  //   key: ACTION_BOOKING.COPY,
  // },
];

export const ACTION_CLEANING_CALENDAR: MenuProps['items'] = [
  {
    key: ACTION_BOOKING.DETAIL,
    label: (
      <div className="action-item">
        <IconExclamation />
        <span>Chi tiết</span>
      </div>
    )
  },
  {
    label: (
      <div className="action-item">
        <IconDirty />
        <span>Phòng bẩn</span>
      </div>
    ),
    key: ACTION_BOOKING.DIRTY_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconClean />
        <span>Làm sạch</span>
      </div>
    ),
    key: ACTION_BOOKING.CLEAN_ROOM
  },
  {
    label: (
      <div className="action-item">
        <IconPlusCircle />
        <span>Thêm dịch vụ</span>
      </div>
    ),
    key: ACTION_BOOKING.ADD_SERVICE
  },
  {
    label: (
      <div className="action-item">
        <IconFix />
        <span>Sửa phòng</span>
      </div>
    ),
    key: ACTION_BOOKING.FIX_ROOM
  }
];

export const getFoundColumns = (
  totalRemain: number,
  onChangeNumberRoom: (value: number, roomTypeId: number) => void,
  onChangePriceType: (value: string | number, roomTypeId: number) => void,
  onChangeEditPrice: (value: CheckboxChangeEvent, roomTypeId: number) => void,
  onChangePrice: (value: number | null, roomTypeId: number) => void
) => {
  const columnFoundRoom: TableProps<RoomType>['columns'] = [
    {
      title: 'Loại phòng',
      dataIndex: 'room_type_name',
      key: 'room_type_name',
      width: '120px'
    },
    {
      title: `Phòng (${totalRemain} còn lại)`,
      dataIndex: 'available_rooms',
      key: 'available_rooms',
      render: (_, { total_rooms, available_rooms, room_type_id, used_rooms = 0 }) => (
        <div className="flex items-center" style={{ gap: '8px' }}>
          <InputNumber
            value={used_rooms}
            controls={false}
            min={0}
            max={available_rooms}
            onChange={value => onChangeNumberRoom(value || 0, room_type_id)}
            addonBefore={
              <MinusOutlined
                onClick={() => {
                  if (used_rooms === 0) {
                    return;
                  }
                  onChangeNumberRoom(used_rooms - 1, room_type_id);
                }}
              />
            }
            addonAfter={
              <PlusOutlined
                onClick={() => {
                  // if (used_rooms >= available_rooms) {
                  //   return;
                  // }
                  onChangeNumberRoom(used_rooms + 1, room_type_id);
                }}
              />
            }
          />
          <span
            className={classNames('flex-shrink-0', {
              required: available_rooms - (used_rooms || 0) < 0
            })}
          >
            {available_rooms - (used_rooms || 0)}/{total_rooms} còn lại{' '}
            <span className="required">*</span>
          </span>
        </div>
      )
    },
    {
      title: 'Loại giá',
      dataIndex: 'pricing',
      key: 'pricing',
      width: '180px',
      render: (_, { pricing, room_type_id, pricelist_id }) => {
        return (
          <Select
            value={pricelist_id || ''}
            className="w-full"
            options={[
              {
                label: 'Mặc định',
                value: ''
              },
              ...pricing.map(item => {
                return {
                  label: item.pricelist_name,
                  value: item.pricelist_id
                };
              })
            ]}
            onChange={e => onChangePriceType(e, room_type_id)}
          />
        );
      }
    },
    {
      title: 'Giá mặc định',
      dataIndex: 'price',
      key: 'price',
      width: '150px',
      align: 'right',
      render: (value, { pricing, pricelist_id, is_editable_price, room_type_id, edited_price }) => {
        if (is_editable_price) {
          return (
            <InputNumber
              value={edited_price !== undefined ? edited_price : value}
              formatter={value => formatInputNumber(value)}
              onChange={e => onChangePrice(e, room_type_id)}
              style={{ width: 110 }}
            />
          );
        }

        let renderPrice: number | undefined = value;
        if (pricelist_id) {
          renderPrice = pricing.find(item => item.pricelist_id === pricelist_id)?.price;
        }
        return <span>{renderPrice?.toLocaleString('vn')}</span>;
      }
    },
    {
      title: 'Chỉnh sửa giá',
      dataIndex: 'is_editable_price',
      key: 'is_editable_price',
      width: '120px',
      align: 'center',
      render: (value: boolean, { room_type_id }) => {
        return <Checkbox checked={value} onChange={e => onChangeEditPrice(e, room_type_id)} />;
      }
    },
    {
      title: 'Khách',
      dataIndex: 'total_rooms',
      key: 'total_rooms',
      width: '160px',
      render: (_, { max_adult, max_child }) => (
        <div className="number-guest-cell">
          <div className="number-guest-cell__type">
            <UserOutlined style={{ fontSize: '20px', color: '#00000040' }} />
            <span>{max_adult || 0}</span>
          </div>
          <div className="number-guest-cell__type">
            <IconBaby style={{ width: '20px', height: '20px' }} />
            <span>{max_child || 0}</span>
          </div>
        </div>
      )
    }
  ];

  return columnFoundRoom;
};

export const columnCleaningDetail: TableProps<any>['columns'] = [
  {
    title: 'Phòng',
    dataIndex: 'roomId',
    key: 'roomId',
    width: '115px',
    align: 'center'
  },
  {
    title: 'Tên khách',
    dataIndex: 'customerName',
    key: 'customerName',
    width: '170px'
  },
  {
    title: 'SL. Khách',
    dataIndex: 'guests',
    key: 'guests',
    width: '110px'
  },
  {
    title: 'Ngày đến',
    dataIndex: 'check_in',
    key: 'check_in',
    width: '120px'
  },
  {
    title: 'Ngày đi',
    dataIndex: 'check_out',
    key: 'check_out',
    width: '120px'
  },
  {
    title: 'DV phòng',
    key: 'service',
    dataIndex: 'service',
    width: '120px'
  },
  {
    title: 'Ghi chú',
    key: 'note',
    dataIndex: 'note'
  }
];

export const fakeDataCleaningDetail: any[] = [
  {
    roomId: 101,
    customerName: 'Nguyễn Thu Hằng',
    guests: 2,
    check_in: '23/4',
    check_out: '26/4',
    service: 'ARR',
    note: ''
  },
  {
    roomId: 102,
    customerName: 'Lê Minh Kha',
    guests: 2,
    check_in: '23/4',
    check_out: '26/4',
    service: 'FS',
    note: ''
  },
  {
    roomId: 103,
    customerName: 'Phạm Phú Lam',
    guests: 1,
    check_in: '23/4',
    check_out: '24/4',
    service: 'ARR',
    note: ''
  },
  {
    roomId: 104,
    customerName: 'Minh Châu',
    guests: 3,
    check_in: '23/4',
    check_out: '27/4',
    service: 'FS',
    note: ''
  }
];

export const getStayingGuestsColumns = (onEditCustomer: (id: number) => void, page: number) => {
  const columnStayingGuest: TableProps<any>['columns'] = [
    {
      title: 'STT',
      dataIndex: 'id',
      key: 'id',
      width: '100px',
      align: 'center',
      render: (value, _, index: number) => (page - 1) * PAGE_SIZE_STAYING_GUESTS + index + 1
    },
    {
      title: 'Tên đầy đủ',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Ngày sinh',
      dataIndex: 'dob',
      key: 'dob',
      width: '110px',
      render: (value: string) => dayjs(value).format('DD/MM/YYYY')
    },
    {
      title: 'SĐT',
      dataIndex: 'phone',
      key: 'phone',
      width: '120px'
    },
    {
      title: 'Giới tính',
      dataIndex: 'gender',
      key: 'gender',
      width: '100px',
      align: 'center',
      render: (value: string) => MAP_GENDER[value]
    },
    {
      title: 'Quốc tịch',
      key: 'country',
      dataIndex: 'country',
      width: '120px',
      render: (country: CountryType) => country.name
    },
    {
      title: 'Giấy tờ',
      key: 'identification',
      dataIndex: 'identification'
    },
    {
      title: 'Phòng',
      key: 'room_name',
      dataIndex: 'room_name',
      width: '120px'
    },
    {
      title: 'Ngày vào',
      key: 'check_in',
      dataIndex: 'check_in',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'Ngày đi',
      key: 'check_out',
      dataIndex: 'check_out',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: '',
      key: '',
      dataIndex: '',
      width: '60px',
      render: (_, { id }) => (
        <Button
          type="link"
          icon={<EditFilled style={{ color: 'rgba(0,0,0,0.54)' }} />}
          onClick={() => onEditCustomer(id)}
        ></Button>
      )
    }
  ];
  return columnStayingGuest;
};

export const columnNationalityStatistic: TableProps<NationalityStatisticType>['columns'] = [
  {
    title: 'Quốc tịch ID',
    key: 'country_id',
    dataIndex: 'country_id',
    width: '90px',
    align: 'center'
  },
  {
    title: 'Quốc tịch',
    key: 'country_name',
    dataIndex: 'country_name',
    width: '90px',
    align: 'center'
  },
  {
    title: 'Số lượng khách lưu trú',
    dataIndex: 'count',
    key: 'count',
    width: '90px',
    align: 'center'
  },
  {
    title: 'Nam',
    dataIndex: 'male',
    key: 'male',
    width: '70px',
    align: 'center'
  },
  {
    title: 'Nữ',
    dataIndex: 'female',
    key: 'female',
    width: '70px',
    align: 'center'
  }
];

export const getCashFlowColumns = (page: number) => {
  const columnCashBookDetail: TableProps<any>['columns'] = [
    {
      title: 'STT',
      dataIndex: 'index',
      key: 'index',
      width: '100px',
      align: 'center',
      render: (value, _, index: number) => (page - 1) * PAGE_SIZE_CASH_FLOW + index + 1
    },
    {
      title: 'Mã đặt phòng',
      dataIndex: 'booking_id',
      key: 'booking_id',
      align: 'center',
      width: '120px'
    },
    {
      title: 'Ngày',
      dataIndex: 'payment_date',
      key: 'payment_date',
      width: '170px',
      render: value => (value ? toLocalTime(value, 'DD/MM/YYYY HH:mm:ss') : '')
    },
    {
      title: 'Số phiếu thu',
      dataIndex: 'id',
      key: 'id',
      width: '120px',
      render: (value, data) =>
        data.payment_type === CASHFLOW_TYPE.INCOME ? (
          <span style={{ color: '#4CAF50' }}>PTM{value}</span>
        ) : (
          ''
        )
    },
    {
      title: 'Số phiếu chi',
      dataIndex: 'id',
      key: 'id',
      width: '120px',
      render: (value, data) =>
        data.payment_type === CASHFLOW_TYPE.PAYMENT ? (
          <span style={{ color: 'rgb(255,87,34)' }}>PCTM{value}</span>
        ) : (
          ''
        )
    },
    {
      title: 'Thu chi',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Mục thu',
      width: '120px',
      align: 'center',
      children: [
        {
          title: 'VND',
          key: 'income',
          dataIndex: 'income',
          width: '120px',
          align: 'center',
          className: 'income-cell',
          render: (value, data) =>
            data.payment_type === CASHFLOW_TYPE.INCOME ? data.amount?.toLocaleString('vn') : 0
        }
      ]
    },
    {
      title: 'Mục chi',
      width: '120px',
      align: 'center',
      children: [
        {
          title: 'VND',
          key: 'outcome',
          dataIndex: 'outcome',
          width: '120px',
          align: 'center',
          className: 'outcome-cell',
          render: (value, data) =>
            data.payment_type === CASHFLOW_TYPE.PAYMENT ? data.amount?.toLocaleString('vn') : 0
        }
      ]
    },
    {
      title: 'Người tạo',
      key: 'user_name',
      dataIndex: 'user_name',
      align: 'center'
    }
  ];

  return columnCashBookDetail;
};

export const ACTION_NIGHT_AUDIT_STEP_0: MenuProps['items'] = [
  {
    label: 'Hủy',
    key: ACTION_BOOKING.CANCEL_BOOKING
  },
  {
    label: 'Nhận phòng',
    key: ACTION_BOOKING.GET_ROOM
  },
  {
    label: 'Đổi ngày ở',
    key: ACTION_BOOKING.CHANGE_DATE
  }
];
export const ACTION_NIGHT_AUDIT_STEP_1: MenuProps['items'] = [
  {
    label: 'Trả phòng',
    key: ACTION_BOOKING.CHECK_OUT
  },
  {
    label: 'Thanh toán',
    key: ACTION_BOOKING.RESOLVE_DOCUMENT
  }
];

const menuAction: any = {
  0: ACTION_NIGHT_AUDIT_STEP_0,
  1: ACTION_NIGHT_AUDIT_STEP_1
};

export const getNightAuditColumnStep0And1 = (onClick: MenuProps['onClick'], step: number) => {
  const menu = menuAction[step];

  const columnNightAudit: TableProps<any>['columns'] = [
    {
      title: '#Số đặt phòng',
      dataIndex: 'booking_id',
      key: 'booking_id',
      width: '140px',
      render: value => `#${value}`
    },
    {
      title: 'Tên khách',
      dataIndex: 'partner_name',
      key: 'partner_name'
    },
    {
      title: 'Phòng',
      dataIndex: 'room_name',
      key: 'room_name',
      render: (value, record) => value || record.room_type_name
    },
    {
      title: 'Loại giá',
      dataIndex: 'pricelist',
      key: 'pricelist',
      width: '120px',
      render: value => value?.name || 'Giá mặc định'
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      width: '130px',
      render: value => (
        <span style={{ color: '#9C4F3B' }}>{MAP_NIGHT_AUDIT_STATUS[value] || ''}</span>
      )
    },
    {
      title: 'Nguồn',
      width: '120px',
      dataIndex: 'source_name',
      key: 'source_name',
      render: value => (value ? value : '-')
    },
    {
      title: 'Ngày Đến',
      width: '140px',
      dataIndex: 'check_in',
      key: 'check_in',
      render: value => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'Ngày Đi',
      width: '140px',
      dataIndex: 'check_out',
      key: 'check_out',
      render: value => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'Tổng tiền',
      key: 'total_price',
      dataIndex: 'total_price',
      width: '140px',
      render: value => Number(value).toLocaleString('vn')
    },
    {
      title: 'Tiền còn lại',
      key: 'remain',
      dataIndex: 'remain',
      width: '200px',
      render: (value, record) => (
        <span>{Number(record.total_price - record.paid_amount).toLocaleString('vn')}</span>
      )
    },
    // {
    //   title: "Tiền đặt trước",
    //   key: "reserve",
    //   dataIndex: "reserve",
    //   width: "140px",
    //   render: (value) => value,
    // },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      width: '100px',
      render: (_, record) => (
        <Dropdown
          trigger={['click']}
          menu={{
            items: menu.filter((item: any) => {
              if (
                item.key === ACTION_BOOKING.CHECK_OUT &&
                record.total_price - record.paid_amount !== 0
              ) {
                return false;
              }
              return !(
                item.key === ACTION_BOOKING.RESOLVE_DOCUMENT &&
                record.total_price - record.paid_amount === 0
              );
            }),
            onClick
          }}
          placement="bottom"
          overlayClassName="night-audit-menu"
          destroyPopupOnHide
        >
          <Button icon={<IconMore />} type="text" />
        </Dropdown>
      )
    }
  ];

  return columnNightAudit;
};

export const getColumnRoomLock = (onClickEdit?: (roomId: number) => void) => {
  const columnRoomLock: TableProps<RoomLockType>['columns'] = [
    {
      title: 'ID',
      dataIndex: 'room_id',
      align: 'center',
      key: 'room_id',
      width: '80px'
    },
    {
      title: 'Phòng',
      dataIndex: 'room_name',
      align: 'center',
      key: 'room_name',
      width: '160px'
    },
    {
      title: 'Ngày bắt đầu',
      dataIndex: 'start_date',
      align: 'center',
      key: 'start_date',
      width: '180px',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'Ngày kết thúc',
      dataIndex: 'end_date',
      align: 'center',
      key: 'end_date',
      width: '180px',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'Người tạo',
      dataIndex: 'create_username',
      align: 'center',
      key: 'create_username',
      width: '160px'
    },
    {
      title: 'Ghi chú',
      dataIndex: 'reason',
      align: 'center',
      key: 'reason',
      width: '220px'
    },
    ...(onClickEdit
      ? [
          {
            title: '',
            dataIndex: '',
            key: 'actions',
            width: '100px',
            render: (_: any, data: RoomLockType) => (
              <Button
                onClick={() => onClickEdit && onClickEdit(data.room_id)}
                style={{ padding: '4px 8px' }}
              >
                <EditFilled />
              </Button>
            )
          }
        ]
      : [])
  ];
  return columnRoomLock;
};

export const columnBreakfasts: TableProps<any>['columns'] = [
  {
    title: 'Mã đặt phòng',
    dataIndex: 'booking_id',
    key: 'booking_id',
    align: 'center',
    width: '80px'
  },
  {
    title: 'Tên khách',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '180px'
  },
  {
    title: 'Phòng',
    dataIndex: 'room_name',
    key: 'room_name',
    align: 'center',
    width: '90px'
  },
  {
    title: 'Người lớn',
    dataIndex: 'adult',
    key: 'adult',
    align: 'center',
    width: '60px'
  },
  {
    title: 'Trẻ em',
    dataIndex: 'child',
    key: 'child',
    align: 'center',
    width: '50px'
  },
  {
    title: 'Ngày đến',
    dataIndex: 'arrival',
    key: 'arrival',
    align: 'center',
    width: '180px',
    render: value => (value ? toLocalTime(value, 'DD/MM/YYYY - HH:mm:ss') : null)
  },
  {
    title: 'Ngày đi',
    dataIndex: 'departure',
    key: 'departure',
    align: 'center',
    width: '180px',
    render: value => (value ? toLocalTime(value, 'DD/MM/YYYY - HH:mm:ss') : null)
  }
];

export const columnTransactions: TableProps<AllPaymentItem>['columns'] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: '80px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {value}
      </span>
    )
  },
  {
    title: 'Mã đặt phòng',
    dataIndex: 'booking_id',
    key: 'booking_id',
    width: '80px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {value}
      </span>
    )
  },
  {
    title: 'Ngày giao dịch',
    dataIndex: 'payment_date',
    key: 'payment_date',
    width: '120px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {toLocalTime(value, 'DD/MM/YYYY HH:mm')}
      </span>
    )
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note',
    width: '140px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {value}
      </span>
    )
  },
  {
    title: 'Số tiền',
    dataIndex: 'amount',
    key: 'amount',
    width: '150px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {formatCurrency(value)}
      </span>
    )
  },
  {
    title: 'Mục',
    dataIndex: 'payment_type',
    key: 'payment_type',
    width: '50px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {MAP_PAYMENT_TYPE[value]}
      </span>
    )
  },
  {
    title: 'Phương thức thanh toán',
    dataIndex: 'payment_method',
    key: 'payment_method',
    width: '120px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {PAYMENT_METHOD_NAMES[value]}
      </span>
    )
  },
  {
    title: 'Người tạo',
    dataIndex: 'user_name',
    key: 'user_name',
    width: '100px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {value}
      </span>
    )
  },
  {
    title: 'Tên khách',
    dataIndex: 'customer_name',
    key: 'customer_name',
    width: '100px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {value}
      </span>
    )
  },
  {
    title: 'Mô tả',
    dataIndex: 'description',
    key: 'description',
    width: '200px',
    render: (value, data) => (
      <span
        className={classNames({
          'line-through': data.state === PAYMENT_STATUS.CANCELLED
        })}
      >
        {value}
      </span>
    )
  }
];

export const columnReservationForecast: TableProps<any>['columns'] = [
  {
    title: 'DATE',
    dataIndex: 'date',
    key: 'date',
    width: '60px',
    render: value => {
      return isNaN(Date.parse(value)) ? value : toLocalTime(value, 'DD/MM/YYYY ddd');
    }
  },
  {
    title: 'CHECK IN',
    align: 'center',
    children: [
      {
        title: 'Room',
        key: 'check_in_room',
        dataIndex: ['check_in', 'room'],
        width: '60px',
        align: 'center',
        render: formatNumber
      },
      {
        title: 'Guest',
        key: 'check_in_guest',
        dataIndex: ['check_in', 'pax'],
        width: '60px',
        align: 'center',
        render: formatNumber
      }
    ]
  },
  {
    title: 'CHECK OUT',
    align: 'center',
    children: [
      {
        title: 'Room',
        key: 'check_out_room',
        dataIndex: ['check_out', 'room'],
        width: '60px',
        align: 'center',
        render: formatNumber
      },
      {
        title: 'Guest',
        key: 'check_out_guest',
        dataIndex: ['check_out', 'pax'],
        width: '60px',
        align: 'center',
        render: formatNumber
      }
    ]
  },
  {
    title: 'OCCUPIED',
    align: 'center',
    children: [
      {
        title: 'Room',
        key: 'occupied_room',
        dataIndex: ['occupied', 'room'],
        width: '60px',
        align: 'center',
        render: formatNumber
      },
      {
        title: '%OCC',
        key: 'occupied_percent',
        dataIndex: ['occupied', 'occupied_percent'],
        width: '60px',
        align: 'center',
        render: value => {
          return value !== '' ? `${Number(value)}%` : '';
        }
      },
      {
        title: 'Guest',
        key: 'occupied_guest',
        dataIndex: ['occupied', 'pax'],
        width: '60px',
        align: 'center',
        render: formatNumber
      }
    ]
  },
  {
    title: 'FINANCIALS',
    align: 'center',
    children: [
      {
        title: 'Revenue',
        key: 'revenue',
        dataIndex: ['financials', 'revenue'],
        width: '60px',
        align: 'center',
        render: formatNumber
      },
      {
        title: 'Gross Profit',
        key: 'gross_profit',
        dataIndex: ['financials', 'gross_profit'],
        width: '60px',
        align: 'center',
        render: formatNumber
      },
      {
        title: 'Net Profit',
        key: 'net_profit',
        dataIndex: ['financials', 'net_profit'],
        width: '60px',
        align: 'center',
        render: formatNumber
      }
    ]
  }
];

export const columnShiftHandover: TableProps<ShiftHandover>['columns'] = [
  {
    title: 'Ca',
    dataIndex: 'id',
    key: 'id',
    width: '40px'
  },
  {
    title: 'Mở ca',
    dataIndex: 'opening_time',
    key: 'opening_time',
    width: '70px',
    render(value) {
      return toLocalTime(value);
    }
  },
  {
    title: 'Đóng ca',
    dataIndex: 'closing_time',
    key: 'closing_time',
    width: '70px',
    render(value) {
      return value ? toLocalTime(value) : '';
    }
  },
  {
    title: 'Người chốt ca',
    dataIndex: 'user_name',
    key: 'user_name',
    width: '80px'
  },
  {
    title: 'Người nhận',
    dataIndex: 'handover_user_name',
    key: 'handover_user_name',
    width: '80px'
  },
  {
    title: 'Đầu ca',
    dataIndex: 'opening_amount',
    key: 'opening_amount',
    width: '50px',
    render: formatMoneyValue
  },
  {
    title: 'Tổng thu',
    dataIndex: 'income_amount',
    key: 'income_amount',
    width: '50px',
    render: formatMoneyValue
  },
  {
    title: 'Tổng chi',
    dataIndex: 'expense_amount',
    key: 'expense_amount',
    width: '50px',
    render: formatMoneyValue
  },
  {
    title: 'Số tiền hiện tại',
    dataIndex: 'current_amount',
    key: 'current_amount',
    width: '60px',
    render: formatMoneyValue
  },
  {
    title: 'Thực tế trong két',
    dataIndex: 'actual_amount',
    key: 'actual_amount',
    width: '60px',
    render: formatMoneyValue
  },
  {
    title: 'Chênh lệch với két',
    dataIndex: 'difference_amount',
    key: 'difference_amount',
    width: '70px',
    render: formatMoneyValue
  },
  {
    title: 'Ghi chú',
    dataIndex: 'note',
    key: 'note',
    width: '50px'
  }
];

export const columnsWarehouseManagement: TableProps<WarehouseProductsType>['columns'] = [
  {
    title: 'ID sản phầm',
    dataIndex: 'product_id',
    key: 'product_id',
    width: '40px'
  },
  {
    title: 'Tên sản phẩm',
    dataIndex: 'product',
    key: 'product',
    width: '200px'
  },
  {
    title: 'Số lượng đặt trước',
    dataIndex: 'reserved_qty',
    key: 'reserved_qty',
    width: '200px'
  },
  {
    title: 'Số lượng còn lại',
    dataIndex: 'free_qty',
    key: 'free_qty',
    width: '200px'
  }
];

export const columnTravelAgency: TableProps<TravelAgencyResponse>['columns'] = [
  {
    title: 'Mã công ty',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: '60px'
  },
  {
    title: 'Tên công ty',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '180px'
  },
  {
    title: 'Số điện thoại',
    dataIndex: 'phone',
    key: 'phone',
    align: 'center',
    width: '100px'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: 'center',
    width: '120px'
  },
  {
    title: 'Mã số thuế',
    dataIndex: 'vat',
    key: 'vat',
    align: 'center',
    width: '100px'
  },
  {
    title: 'Địa chỉ',
    dataIndex: 'street',
    key: 'street',
    align: 'center',
    width: '120px'
  },
  {
    title: 'Khu vực',
    dataIndex: 'state_name',
    key: 'state_name',
    align: 'center',
    width: '80px'
  },
  {
    title: 'Quốc gia',
    dataIndex: 'country_name',
    key: 'country_name',
    align: 'center',
    width: '80px'
  }
];

// List group
export const getColumnListGroup = (page: number) => {
  const columnListGroup: TableProps<ResponseGroupBookings>['columns'] = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: '100px',
      align: 'center',
      render: (value, _, index: number) => (page - 1) * PAGE_SIZE_STAYING_GUESTS + index + 1
    },
    // {
    //   title: 'Confirm#',
    //   dataIndex: 'confirm',
    //   key: 'confirm',
    //   width: '150px',
    //   align: 'center'
    // },
    {
      title: 'Group Code',
      dataIndex: 'id',
      key: 'id',
      width: '200px',
      align: 'center'
    },
    {
      title: 'Group Name',
      dataIndex: 'name',
      key: 'group_name',
      width: '200px'
    },
    {
      title: 'TA Name',
      dataIndex: 'travel_agency_name',
      key: 'travel_agency_name',
      width: '400px'
    },
    {
      title: 'Company',
      dataIndex: 'company_name',
      key: 'company_name',
      width: '150px'
    },
    {
      title: 'Source',
      dataIndex: 'source_name',
      key: 'source_name',
      width: '400px'
    },
    {
      title: 'VIP Level',
      dataIndex: 'vip_level',
      key: 'vip_level',
      width: '120px',
      render: (value: string) => MAP_VIP_LEVEL[value]
    },
    {
      title: 'First Arrival',
      dataIndex: 'first_arrival',
      key: 'first_arrival',
      width: '200px',
      align: 'center',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'Last Departure',
      dataIndex: 'last_departure',
      key: 'last_departure',
      width: '200px',
      align: 'center',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'Notice',
      dataIndex: 'note',
      key: 'note',
      width: '200px'
    }
  ];

  return columnListGroup;
};

export const ACTION_SUBGROUP = {
  DETAIL: 'DETAIL',
  GENERATE_ROOM_LIST: 'GENERATE_ROOM_LIST',
  CANCEL_GROUP: 'CANCEL_GROUP'
};

export const ACTION_SUBGROUP_OPTIONS: MenuProps['items'] = [
  {
    key: ACTION_SUBGROUP.DETAIL,
    label: 'Chi tiết'
  },
  {
    key: ACTION_SUBGROUP.GENERATE_ROOM_LIST,
    label: 'Tạo room list'
  },
  {
    key: ACTION_SUBGROUP.CANCEL_GROUP,
    label: 'Hủy sub group'
  }
];

export const getColumnDetailGroup = (onClick: (menu: any) => void) => {
  const columnDetailGroup: TableProps<SubGroup>['columns'] = [
    {
      title: 'STT',
      dataIndex: 'stt',
      key: 'stt',
      width: '60px',
      align: 'center',
      render: (value, _, index: number) => index + 1
    },
    {
      title: 'Type',
      dataIndex: 'room_type_name',
      key: 'room_type_name',
      width: '80px',
      align: 'center'
    },
    {
      title: '#Rm',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '80px',
      align: 'center'
    },
    {
      title: '#RPk',
      dataIndex: 'roomed',
      key: 'roomed',
      width: '80px',
      align: 'center'
    },
    {
      title: '#Gst',
      dataIndex: 'pax',
      key: 'pax',
      width: '80px',
      align: 'center'
    },
    {
      title: '#GPkp',
      dataIndex: 'roomed_pax',
      key: 'roomed_pax',
      width: '80px',
      align: 'center'
    },
    {
      title: 'From Date',
      dataIndex: 'check_in',
      key: 'check_in',
      width: '180px',
      align: 'center',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'To Date',
      dataIndex: 'check_out',
      key: 'check_out',
      width: '180px',
      align: 'center',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'Rate',
      dataIndex: 'rate_amount',
      key: 'rate_amount',
      width: '180px',
      align: 'center',
      render: formatNumber
    },
    {
      title: 'Rm Total',
      dataIndex: 'rmTotal',
      key: 'rmTotal',
      width: '180px',
      align: 'center',
      render: (_, data) => {
        const startDate = dayjs(data.check_in);
        const endDate = dayjs(data.check_out);
        const night = endDate
          .set('hour', 23)
          .set('minute', 59)
          .diff(startDate.set('hour', 0).set('minute', 0), 'day');
        return formatNumber(data.rate_amount * night);
      }
    }
    // {
    //   title: 'SP Total',
    //   dataIndex: 'spTotal',
    //   key: 'spTotal',
    //   width: '150px',
    //   align: 'center',
    //   render: formatNumber
    // }
  ];

  columnDetailGroup.forEach(col => {
    const existingRender = col.render;

    col.render = (text, record, index) => {
      const actions = ACTION_SUBGROUP_OPTIONS.filter(item => {
        if (record.roomed >= record.quantity && item?.key === ACTION_SUBGROUP.GENERATE_ROOM_LIST) {
          return false;
        }
        return true;
      });

      return (
        <Dropdown
          trigger={['click']}
          menu={{ items: actions, onClick }}
          placement="bottom"
          overlayClassName="action-subgroup"
          destroyPopupOnHide
        >
          <div>{existingRender ? existingRender(text, record, index) : text}</div>
        </Dropdown>
      );
    };
  });

  return columnDetailGroup;
};

export const getColumnChangeDateGroup = () => {
  const columnChangeDateGroup: TableProps<SubGroup>['columns'] = [
    {
      title: 'Confirm',
      dataIndex: 'groupId',
      key: 'groupId',
      width: '80px',
      align: 'center'
    },
    {
      title: 'Group subcode',
      dataIndex: 'id',
      key: 'id',
      width: '100px',
      align: 'center'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      align: 'center',
      render: text => MappingColumnBookingStatus[text] || ''
    },
    {
      title: 'Room Type',
      dataIndex: 'room_type_name',
      key: 'room_type_name',
      width: '80px',
      align: 'center'
    },
    {
      title: 'From Date',
      dataIndex: 'check_in',
      key: 'check_in',
      width: '180px',
      align: 'center',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    },
    {
      title: 'To Date',
      dataIndex: 'check_out',
      key: 'check_out',
      width: '180px',
      align: 'center',
      render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
    }
  ];

  return columnChangeDateGroup;
};

export const columnsUsedRoomsTab: TableProps<any>['columns'] = [
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: '60px',
    render: text => MappingColumnBookingStatus[text] || ''
  },
  {
    title: 'Mã đặt phòng',
    dataIndex: 'booking_id',
    key: 'booking_id',
    align: 'center',
    width: '75px'
  },
  {
    title: 'Mã xác thực',
    dataIndex: 'booking_line_id',
    key: 'booking_line_id',
    align: 'center',
    width: '65px'
  },
  {
    title: 'Tên khách',
    dataIndex: 'partner_name',
    key: 'partner_name',
    align: 'center',
    width: '140px'
  },
  {
    title: 'Phòng',
    dataIndex: 'room_name',
    key: 'room_name',
    align: 'center',
    width: '50px'
  },
  {
    title: 'Ngày đến',
    dataIndex: 'check_in',
    key: 'check_in',
    align: 'center',
    width: '90px',
    render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
  },
  {
    title: 'Ngày đi',
    dataIndex: 'check_out',
    key: 'check_out',
    align: 'center',
    width: '90px',
    render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
  }
];

export const columnSubGroupsTab: TableProps<any>['columns'] = [
  {
    title: 'Số xác nhận',
    dataIndex: 'ref_code',
    key: 'ref_code',
    align: 'center',
    width: '80px'
  },
  {
    title: 'Mã đoàn',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    width: '80px'
  },
  {
    title: 'Tên đoàn',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: '100px'
  },
  {
    title: 'TA/ Công ty',
    dataIndex: 'travel_agency_name',
    key: 'travel_agency_name',
    align: 'center',
    width: '140px'
  },
  {
    title: 'Loại phòng',
    dataIndex: 'room_type_name',
    key: 'room_type_name',
    align: 'center',
    width: '80px'
  },
  {
    title: 'Số lượng phòng',
    dataIndex: 'remain_room',
    key: 'remain_room',
    align: 'center',
    width: '60px'
  },
  {
    title: 'Số lượng khách',
    dataIndex: 'pax',
    key: 'pax',
    align: 'center',
    width: '60px'
  },
  {
    title: 'Ngày đến',
    dataIndex: 'check_in',
    key: 'check_in',
    align: 'center',
    width: '60px'
  },
  {
    title: 'Ngày đi',
    dataIndex: 'check_out',
    key: 'check_out',
    align: 'center',
    width: '60px'
  }
];

export const columnFocDetailsTab: TableProps<any>['columns'] = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    align: 'center',
    width: '80px'
  },
  {
    title: 'Mã đặt phòng',
    dataIndex: 'booking_id',
    key: 'booking_id',
    align: 'center',
    width: '80px'
  },
  {
    title: 'Loại phòng',
    dataIndex: 'room_type',
    key: 'room_type',
    align: 'center',
    width: '80px'
  },
  {
    title: 'Số lượng',
    dataIndex: 'foc_qty',
    key: 'foc_qty',
    align: 'center',
    width: '60px'
  },
  {
    title: 'Số tiền',
    dataIndex: 'foc_price',
    key: 'foc_price',
    align: 'center',
    width: '60px'
  },
  {
    title: 'Ngày đến',
    dataIndex: 'check_in',
    key: 'check_in',
    align: 'center',
    width: '60px',
    render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
  },
  {
    title: 'Ngày đi',
    dataIndex: 'check_out',
    key: 'check_out',
    align: 'center',
    width: '60px',
    render: (value: string) => toLocalTime(value, 'DD/MM/YYYY')
  }
];
